import React, { FunctionComponent } from "react";

type LayoutProps = {
  children: React.ReactNode;
};

const Layout: FunctionComponent<LayoutProps> = (props: LayoutProps) => (
  <>{props.children}</>
);

export default Layout;
