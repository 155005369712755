import React, { FunctionComponent } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Home from "./Home";
import Deny from "./Deny";
import Welcome from "./Welcome";

type RoutesProps = {};

const Routes: FunctionComponent<RoutesProps> = (_props) => {
  return (
    <div>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/welcome" component={Welcome} />
        <Route path="/deny" component={Deny} />
        <Redirect to="/" />
      </Switch>
    </div>
  );
};

export default Routes;
