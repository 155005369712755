import React from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Layout from "../hoc/Layout";
import Routes from "../components/Routes";

function App() {
  return (
    <BrowserRouter>
      <Layout data-testid="layout">
        <Routes data-testid="routes" />
      </Layout>
    </BrowserRouter>
  );
}

export default App;
