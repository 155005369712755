import React, { useEffect } from "react";

const Home: React.FC = () => {
  useEffect(() => {
    document.title = "DAP Auth Spike";
  });
  return <div id="login-1">Home</div>;
};

export default Home;
